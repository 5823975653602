import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Button from "../components/Button"
import PartnerCards from "../components/PartnerCards"
import Carousel from "../components/Carousel"
import Newsletter from "../components/Newsletter"
import Layout from "../layouts/Layout"

export default props => {
  const {
    description,
    questions,
    gallery,
    name,
    related,
    summary,
    thumbnail,
    url,
  } = props.data.item

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full sm:w-2/3 pb-8">
              {gallery && gallery.length === 1 && (
                <Img
                className="max-w-sm max-h-sm"
                  fluid={gallery[0].localFile.childImageSharp.fluid}
                  alt={name}
                />
              )}
              {gallery && gallery.length > 1 && <Carousel images={gallery} />}
            </div>
            <div className="w-full lg:w-2/3">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-green-600 sm:text-2xl">
                {summary}
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
              {url && (
                <div className="mt-8">
                  <Button href={url}>Partner Website</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-0 py-1 lg:py-1">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8">
              {questions && (
                <div className="my-1 text-base text-gray-900 whitespace-pre-line">
                  {questions.questions}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {related && (
        <div className="bg-gray-100 py-12 lg:py-16">
          <div className="container">
            <h2 className="text-2xl sm:text-2xl leading-tight font-extrabold tracking-tight text-gray-900 mb-6">
              More partners
            </h2>
          </div>
          <PartnerCards items={related} hideLastItemOnMobile={true} />
        </div>
      )}

    </Layout>
  )
}

export const query = graphql`
  query PartnerItemQUery($slug: String!) {
    item: contentfulPartner(slug: { eq: $slug }) {
      description {
        description
      }
      questions {
        questions
      }
      gallery {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 960, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      name
      related {
        ...PartnerCard
      }
      summary
      thumbnail {
        localFile {
          publicURL
        }
      }
      url
    }
  }
`
