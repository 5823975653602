import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from "../components/Button"

const PartnerCard = props => {
  const { name, slug, summary, thumbnail, url, description } = props

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden group">
      {/*<Link to={`/${slug}`}>*/}
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
          <Img fluid={thumbnail.localFile.childImageSharp.fluid} alt={name} />
        </div>
        <div className="p-4 sm:p-5">
          <h1 className="sm:text-lg text-gray-900 font-semibold">{name}</h1>
          {/*<p className="text-sm sm:text-base text-gray-700">{summary}</p>*/}
          {description && (
            <div className="my-4 text-base text-gray-700 whitespace-pre-line">
              {description.description}
            </div>
          )}
          {url && (
            <div className="mt-8">
              <Button href={url}>Partner Website</Button>
            </div>
          )}
        </div>
      {/*</Link>*/}
    </div>
  )
}

PartnerCard.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    localFile: PropTypes.object,
  }),
}

export default PartnerCard

export const query = graphql`
  fragment PartnerCard on ContentfulPartner {
    id
    name
    slug
    thumbnail {
      localFile {
        childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    summary
    emojis
    url
    description {
      description
    }
  }
`
